import React from 'react';
import { Link } from 'react-router-dom';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktSubscription from 'components/mkt/mkt-subscription';
import MktNav from 'components/navs/mkt-nav';
import MktHero from 'components/mkt/mkt-hero';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import CookieBanner from 'components/misc/cookie-banner';
import MktColumn from 'components/mkt/mkt-column';
import MktCollapse from 'components/mkt/mkt-collapse';
import MktTestimonial from 'components/mkt/mkt-testimonial';
import MktFeaturedProviders from 'components/mkt/mkt-featured-providers';
import FAWrapper from 'components/vendors/fa-wrapper';
import { HiwTime, HiwServices, HiwTravel } from 'utils/assets/how-it-works';
import { Wedding, Work, Shopping, Celebration, Party, NightOut, Bridesmaids  } from 'utils/assets/images';
import { HairBrush, CurlingIron, HairDryer, FlatIron, SprayBottle, Makeup } from 'utils/assets/icons';
import { Container, Row, Col } from 'reactstrap';
import GetAppBanner from 'components/misc/get-app-banner';
import { StateBanner1, StateBanner2 } from 'utils/assets/branding';

const MktIndexRoute = (props) => {
  const { history } = props;


  // Render
  return (
  	<div className='route'>
      <Head title='Blush - On-Demand Beauty'/>
      <div>
      </div>
      <GetAppBanner />
      <MktAnnouncement />
      <MktSubscription />
      <CookieBanner />
      <MktNav/>
      <MktHero className=''/>
      <main className='mkt-main'>

      <section className='mkt-section white-bg'>
          <div className='mkt-section-title'>
            <Container>
              <h2>Servicing Areas</h2>
              <hr/>
              <img src={StateBanner2} style={{ height: 'auto', width: '100%' }} />
              <div style={{
                marginTop: '20px'
              }}></div>
              <img src={StateBanner1} style={{ height: 'auto', width: '100%' }} />
            </Container>
          </div>
          </section>
        <section className='mkt-section white-bg'>
          <div className='mkt-section-title'>
            <Container>
              <h2>How it Works</h2>
              <hr/>
            </Container>
          </div>
          <div className='mkt-section-body sm flex'>
    		    <Container>
              <Row>
                <Col md='4'>
                  <MktColumn
                    type='hiw'
                    img={HiwTime}
                    title='Tell Us When And Where'
                    msg="Onsite Hair and Makeup in your Home, Office, Hotel...We will meet you almost anywhere! Simply select a day and a time that works for you and we're on our way."
                    className='animated fadeInUp'
                  />
                </Col>
                <Col md='4'>
                  <MktColumn
                    type='hiw'
                    img={HiwServices}
                    title='Choose Your Services'
                    msg='Blowouts, curls, braids, special occasion hair and makeup application.'
                    className='animated fadeInUp delay-200ms'
                  />
                </Col>
                <Col md='4'>
                  <MktColumn
                    type='hiw'
                    img={HiwTravel}
                    title="Now Relax, a Hair and Makeup artist is on their way!"
                    msg='Get ready to feel beautiful.'
                    className='animated fadeInUp delay-400ms'
                  />
                </Col>
              </Row>
                <Link to='/book' className='btn btn-primary btn-md animated fadeInLeft delay-400ms'>Book Now<FAWrapper icon={'chevron-right'} className='sm ml-2'/></Link>
            </Container>
          </div>
        </section>

        <MktFeaturedProviders />

        <section className='mkt-section'>
          <div className='mkt-section-title'>
            <Container>
              <h2>Why Blush</h2>
              <hr/>
            </Container>
          </div>
          <div className='mkt-section-body md flex'>
            <Container>
                <Row className='flex'>
                  <Col md='6'>
                    <div className='mkt-section-content'>
                      <h6 className='p animated fadeInLeft'>
                        We know you’re busy taking care of both your career and your loved ones,
                        and perhaps more often than not,
                        you run out of time to take care of yourself.
                        Our Blush Hair and Makeup Artists will make you look and feel great, freeing up time for what really matters most.
                        <ul className='p-15'>
                          <li>Beauty is now easier to work into your day</li>
                          <li>Numerous Hair and Makeup Artists you can choose from</li>
                          <li>Anywhere at anytime in the Metro Area</li>
                        </ul>
                      </h6>

                      <Link to='/book' className='btn btn-primary btn-md animated fadeInLeft delay-400ms'>Book Now<FAWrapper icon={'chevron-right'} className='sm ml-2'/></Link>
                    </div>
                  </Col>
                  <Col md='6'>
                    <div className='mkt-grid-container'>
                      <Row>
                        <Col xs='6' md='4' className='fadeInUp'>
                          <div className='mkt-grid-block primary-bg'>
                            <img className='mkt-grid-icon' src={HairBrush}/>
                          </div>
                        </Col>
                        <Col xs='6' md='4' className='fadeInUp'>
                          <div className='mkt-grid-block secondary-bg'>
                            <img className='mkt-grid-icon' src={CurlingIron}/>
                          </div>
                        </Col>
                        <Col xs='6' md='4' className='fadeInUp'>
                          <div className='mkt-grid-block accent-bg'>
                            <img className='mkt-grid-icon' src={HairDryer}/>
                          </div>
                        </Col>
                        <Col xs='6' md='4' className='fadeInUp'>
                          <div className='mkt-grid-block darker-gray-bg'>
                            <img className='mkt-grid-icon' src={FlatIron}/>
                          </div>
                        </Col>
                        <Col xs='6' md='4' className='fadeInUp'>
                          <div className='mkt-grid-block light-gray-bg'>
                            <img className='mkt-grid-icon' src={SprayBottle}/>
                          </div>
                        </Col>
                        <Col xs='6' md='4' className='fadeInUp'>
                          <div className='mkt-grid-block lighter-gray-bg'>
                            <img className='mkt-grid-icon' src={Makeup}/>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-title'>
            <Container>
              <h2>Frequently Asked Questions</h2>
              <hr/>
            </Container>
          </div>
          <div className='mkt-section-body lg flex-top'>
            <Container>

                <MktCollapse isOpen={false} icon='question-mark' title='How it works' className='animated fadeInUp'>
                  <h6>
                    From day time glam to wedding glam, our Blush Hair and Makeup Artists have you covered!
                    Choose your date, time and onsite hair and makeup location and let our Pros take care of the rest!
                  </h6>

                </MktCollapse>

                <MktCollapse isOpen={false} icon='question-mark' title='How do I prepare for my appointment?' className='animated fadeInUp delay-100ms'>
                  <h6>
                    In order for our Blush Professionals to provide the ultimate onsite hair and makeup experience,
                    we ask that you have an area prepared for their arrival.
                    The spot you choose will need a chair, an outlet and a surface area.
                  </h6>
                  <h6><b>Makeup:</b> Please have your face makeup free and cleansed. Having a higher chair, such as a bar chair, is helpful.</h6>
                  <h6><b>Haircuts:</b> Have your hair wet, towel dried and clean prior to the arrival of a Blush Pro. Please have a broom or vacuum handy so the Pro can clean up after the haircut.</h6>
                  <h6><b>Blowouts:</b> It is recommended you have your hair wet and clean prior to the arrival of a Blush Pro.</h6>
                  <h6><b>Special Occasion Hair:</b> For best results, wash your hair the night prior (do not style with hot tools) and wear a loose fitting or button down shirt.</h6>
                </MktCollapse>

                <MktCollapse isOpen={false} icon='question-mark' title='How do I schedule a group booking?' className='animated fadeInUp delay-200ms'>
                  <h6>Please visit <Link to='/special-events'>Group Bookings</Link> to submit your request and we'll get back to you within 24 hours!</h6>
                </MktCollapse>

                <MktCollapse isOpen={false} icon='question-mark' title='How does pricing work?' className='animated fadeInUp delay-300ms'>
                  <h6>For pricing, please see our <Link to='/our-services'>Services</Link> page.</h6>
                </MktCollapse>

                <MktCollapse isOpen={false} icon='question-mark' title='How am I charged?' className='animated fadeInUp delay-300ms'>
                  <h6>
                    Every booking requires a credit card on file. After the services are rendered, your
                    card will be charged and at that point you have the opportunity to leave a tip.
                  </h6>
                </MktCollapse>

                <MktCollapse isOpen={false} icon='question-mark' title='Is there a travel fee?' className='animated fadeInUp delay-300ms'>
                  <h6>
                    We do our best to provide you with a Blush Pro anywhere you are. If for some reason
                    you are out of the range for a Blush Pro, we charge a travel fee starting at $20.
                    If travel is required, on-demand service times are subject to change.
                  </h6>
                </MktCollapse>

                <MktCollapse isOpen={false} icon='question-mark' title='What if I need to cancel?' className='animated fadeInUp delay-400ms'>
                  <h6>
                    In your profile you have the capability to cancel your appointment.  If you cancel prior to
                    the 24-hour period you will be refunded your service(s). If you cancel within a 24-hour
                    period you will be charged 50% of the service(s). If you cancel within an hour of your
                    service you will be charged 100% of the service(s).
                  </h6>
                </MktCollapse>

                <MktCollapse isOpen={false} icon='question-mark' title='How do I become a Blush Pro?' className='animated fadeInUp delay-400ms'>
                  <h6>Do you have a Cosmetology license? Simply visit the <Link to='/for-stylists'>For Stylists</Link> page and follow the easy steps to become a part of the Blush Pro Network!</h6>
                </MktCollapse>

            </Container>
          </div>
        </section>

        <section className='mkt-section white-bg'>
          <div className='mkt-section-title'>
            <Container>
              <h2>Have A Special Event?</h2>
              <hr/>
            </Container>
          </div>
          <div className='mkt-section-body md flex'>
            <Container>
                <Row className='flex'>

                  <Col md={{size: 7, order: 2}}>
                    <img className='mkt-section-img animated fadeInUp' src={Bridesmaids} />
                  </Col>

                  <Col md={{size: 5, order: 1}}>
                    <div className='mkt-section-content'>
                      <h6 className='p  animated fadeInLeft'>A picture is worth a thousand words and a lifetime of memories. From charity events, galas, school dances, bachelorette parties, weddings and other celebrations, our experts are here for your hair and make-up needs.</h6>
                      <Link to='/special-events' className='btn btn-primary btn-lg  animated fadeInLeft delay-200ms'>Inquire about an event<FAWrapper icon={'chevron-right'} className='sm ml-2'/></Link>
                    </div>
                  </Col>

                </Row>
            </Container>
          </div>
        </section>

        <MktConsumerPrefooter />

			</main>

      <MktFooter/>
  	</div>
	);
}

export default MktIndexRoute;

