import React, { useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { withSession } from 'contexts/session-context';
import { MobileNavContext } from 'contexts/mobile-nav-context';
import UserDropdown from 'components/user/user-dropdown';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Nav, Navbar, NavbarToggler, NavbarBrand, NavItem } from 'reactstrap';
import { BlushLogo } from 'utils/assets/branding';

const MktNav = (props) => {
	const { session } = props;

  const logout = () => {
  	props.session.logout();
  	props.history.push('/');
  }

	return (
		<Navbar className='navbar container-fluid flex-between'>
			<NavbarBrand href='/'><img src={BlushLogo} /></NavbarBrand>

			<div className='flex-end'>

				<MobileNavContext.Consumer>
					{state => (
						<NavbarToggler className={state.isOpen ? 'btn hamburger hamburger--squeeze is-active' : 'btn hamburger hamburger--squeeze'} type='button' onClick={state.toggleOpen}>
						  <span className='hamburger-box'>
						    <span className='hamburger-inner'></span>
						  </span>
						</NavbarToggler>
					)}
				</MobileNavContext.Consumer>
				<Nav className='ml-auto flex-end' navbar>
					<NavItem><NavLink to='/about' className='btn btn-nav-gray' activeClassName='active'>About</NavLink></NavItem>
					<NavItem><NavLink to='/our-services' className='btn btn-nav-gray' activeClassName='active'>Our Services</NavLink></NavItem>
					<NavItem><NavLink to='/trust-safety' className='btn btn-nav-gray' activeClassName='active'>Trust &amp; Safety</NavLink></NavItem>
					<NavItem><NavLink to='/faq' className='btn btn-nav-gray' activeClassName='active'>FAQ</NavLink></NavItem>
					<NavItem><NavLink to='/special-events' className='btn btn-nav-gray' activeClassName='active'>Special Events</NavLink></NavItem>
					<NavItem>
						<a href="https://cdn.giftup.app/dist/checkout.html?site=70ce1775-75cc-4997-34c4-08da9590d9f1&environment=Production&instance=5tan7839tsk#" target="_blank" className='btn btn-nav-gray'>
							Gift Certificate
						</a>
					</NavItem>
					{session.isAuthenticated() ? (
						<div className='flex-end'>
							<NavItem><NavLink to='/book' className='btn btn-nav-gray' activeClassName='active'>Book Now</NavLink></NavItem>
							<UserDropdown user={session.user} logout={logout} />
						</div>
					) : (
						<div className='flex-end'>
							<NavItem><NavLink to='/login' className='btn btn-nav-gray'>Login</NavLink></NavItem>
							<NavItem className='flex'><NavLink to='/register' className='btn btn-primary btn-md  ml-15'>Get Started<FAWrapper icon='chevron-right' className='sm ml-2'/></NavLink></NavItem>
						</div>
					)}
				</Nav>

			</div>
		</Navbar>
	)
}

export default withSession(withRouter(MktNav));
